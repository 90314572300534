<template>
  <div id="patrolRecord">
    <div class="filter-panel">
      <CSSelect style="margin-right: 0">
        <el-date-picker
          type="date"
          placeholder="请选择开始时间"
          v-model="queryLogInfo.startTime"
          style="width: 157px"
          value-format="yyyy-MM-dd"
          prefix-icon="el-icon-time"
          :editable="false"
        >
        </el-date-picker>
      </CSSelect>
      <span class="inline-gray"></span>
      <CSSelect>
        <el-date-picker
          type="date"
          placeholder="请选择开始时间"
          v-model="queryLogInfo.endTime"
          style="width: 157px"
          value-format="yyyy-MM-dd"
          prefix-icon="el-icon-time"
          :editable="false"
        >
        </el-date-picker>
      </CSSelect>
      <input
        type="text"
        v-model="queryLogInfo.name"
        class="hy-search-input"
        placeholder="搜索机器人/任务名称"
      />
      <CSSelect>
        <select class="hy-select" v-model="queryLogInfo.execute">
          <option value="">全部执行结果</option>
          <option :value="item.id" v-for="item in ROBOT_ARR" :key="item.id">{{item.value}}</option>
        </select>
      </CSSelect>
      <button class="btn btn-primary" @click="getPatrolTaskLog()">查询</button>
    </div>
    <div class="result-panel">
      <CSTable :thead-top="filterHeight">
        <template v-slot:thead>
          <tr>
            <th>启动时间</th>
            <th>返回时间</th>
            <th>机器人</th>
            <th>任务名称</th>
            <th>巡视点</th>
            <th>图片</th>
            <th>录像</th>
            <th>执行结果</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr v-for="item in patrolTaskLog" :key="item.id">
            <td style="min-width:125px;">{{ item.createTime }}</td>
            <td style="min-width:125px;">{{ item.endTime?item.endTime:'-' }}</td>
            <td>{{item.robotName}}</td>
            <td>{{ item.taskName }}</td>
            <td>
              <span v-if="item.patrolPointNum" class="allow-click" @click="lookPatrolPointDetails(item)">{{
                item.patrolPointNum
              }}</span>
              <span v-else>-</span>
            </td>
            <td>
              <span class="allow-click" @click="lookImage(item)">查看</span>
            </td>
            <td>
              <span
                class="allow-click"
                @click="lookVideo(item)"
                v-if="item.videoUrl"
                >查看</span
              >
              <span v-else>-</span>
            </td>
            <td>{{ROTOR_TATE[item.taskResult]?ROTOR_TATE[item.taskResult]:'-'}}</td>
          </tr>
        </template>
      </CSTable>
      <!-- //分页 -->
      <Pagination name="pagination" componentName="Pagination"></Pagination>
    </div>
    <CSDialog
      :dialog-visible="imageDialog.visible"
      dialog-title="查看图片"
      dialog-width="1030px"
      :dialog-show-confirm-btn="false"
      dialog-cancel-btn-text="关闭"
      @onClose="imageDialog.visible = false"
      dialogHeaderClass="alert-bg"
    >
      <template v-slot:dialog-content>
        <div class="capture-img">
          <div class="title">机器人名称：{{imageDialog.data.robotName }}</div>
          <div class="content" @scroll="loadScreenshot">
            <div class="item-wrap">
              <div
                class="item"
                v-for="(img, index) in imageDialog.picture"
                :key="`${img.owner.id}_${index}`"
              >
                <img
                  style="width: 100%; height: 160px"
                  :src="img.url"
                  alt=""
                  @click="lookBigImage(imageDialog.data, index)"
                />
                <span>{{ img.lastModified }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </CSDialog>
    <CSDialog
      :dialog-visible="bigImageDialog.visible"
      dialog-title="查看图片"
      dialog-width="1020px"
      :dialog-show-confirm-btn="false"
      dialog-cancel-btn-text="关闭"
      @onClose="bigImageDialog.visible = false"
      dialogHeaderClass="alert-bg"
    >
      <template v-slot:dialog-content>
        <div style="padding: 30px; position: relative">
          <el-carousel
            trigger="click"
            height="540px"
            :initial-index="bigImageDialog.initIndex"
            :autoplay="false"
            :loop="false"
            indicator-position="none"
            @change="changeFlightScreenshot"
          >
            <el-carousel-item
              v-for="(img, index) in imageDialog.picture"
              :key="`${img.owner.id}_${index}`"
            >
              <div class="video-drone-name">
                
                <span class="pull-right">机器人名称：{{ imageDialog.data.robotName }}</span>
              </div>
              <img style="width: 100%; height: 100%" :src="img.url" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </template>
    </CSDialog>
    <CSDialog
      dialog-title="查看录像"
      dialog-width="1020px"
      :dialog-visible="lookVideoVisible"
      :dialog-show-confirm-btn="false"
      dialog-cancel-btn-text="关闭"
      @onClose="lookVideoVisible = false"
      dialogHeaderClass="alert-bg"
    >
      <template v-slot:dialog-content>
        <div style="padding: 0 30px 0px 30px">
          <div style="padding: 30px; position: relative">
            <div class="video-drone-name" style="top: 30px; padding: 10px 15px;text-align: right ;width:900px;">
              机器人名称：{{items.robotName}}
            </div>
            <video
              :src='"https://cosmos-robot.oss-cn-beijing.aliyuncs.com/"+items.videoUrl'
              muted
              autoplay
              controls
              style="width: 960px; min-height: 500px"
              controlslist="nodownload noremoteplayback noplaybackrate"
              disablePictureInPicture
            ></video>
          </div>
        </div>
      </template>
    </CSDialog>
    <CSDialog
      dialog-title="查看巡视点详情"
      dialog-width="920px"
      :dialog-visible="patrolPointDetailsVisible"
      :dialog-show-confirm-btn="false"
      dialog-cancel-btn-text="关闭"
      @onClose="patrolPointDetailsVisible = false"
      dialogHeaderClass="alert-bg"
    >
      <template v-slot:dialog-content>
        <div style="padding: 0 30px 30px 30px">
          <patrolMap :item="items"></patrolMap>
        </div>
      </template>
    </CSDialog>
  </div>
</template>

<script>
import CSSelect from "@/components/common/CSSelect";
import CSTable from "@/components/common/CSTable";
import CSDialog from "@/components/common/CSDialog";
import Pagination from "@/components/Pagination";
import patrolMap from "./PatrolMap.vue";
import { queryPatrolTaskLogByConditionUrl } from "@/requestUrl";
import dayjs from "dayjs";
import OSS from "ali-oss";
const ROTOR_TATE={
  1:'已完成',
  2:'断开',
  3:'中止返回'
}
const ROBOT_ARR = [{id:1,value:'已完成'},{id:2,value:'断开'},{id:3,value:'中止返回'}];
export default {
  name: "patrolRecord",
  components: {
    CSSelect,
    CSTable,
    CSDialog,
    Pagination,
    patrolMap,
  },
  
  data() {
    return {
      ROBOT_ARR,
       ROTOR_TATE,
      filterHeight: "",
      regionCode: this.$vc.getCurrentRegion().code,
      // 查询巡视记录所需信息
      queryLogInfo: {
        startTime: dayjs().subtract(7, "day").format("YYYY-MM-DD 00:00"),
        endTime: dayjs().format("YYYY-MM-DD 23:59"),
        name: "",
        execute: "",
      },
      // 存储临时stsToken
      stsTokenStorage: {},
      patrolTaskLog: [],
      lookImgVisible: false,
      lookImgVisibleTwo: false,
      lookVideoVisible: false,
      patrolPointDetailsVisible: false,
      items: [],
      imgIndex: 0,
      imageDialog: {
        visible: false,
        data: {},
        picture: [],
      },
      bigImageDialog: {
        visible: false,
        data: {},
        initIndex: 0,
      },
    };
  },
  mounted() {
    this.filterHeight = document.querySelector(".filter-panel")?.offsetHeight;
    window.addEventListener("resize", () => {
      this.filterHeight = document.querySelector(".filter-panel")?.offsetHeight;
    });
    window.addEventListener('keydown',this.getPatrolTaskLogKey);
    this.getPatrolTaskLog();
    this.$vc.on(this.$route.path, 'pagination_page', 'event', (page) => {
          console.log(page);
           this.getPatrolTaskLog(page);
        })
  },
  beforeDestroy(){
    window.removeEventListener('keydown',this.getPatrolTaskLogKey);
  },
  deactivated(){
    window.removeEventListener('keydown',this.getPatrolTaskLogKey);
  },
  methods: {
    getPatrolTaskLogKey(e){
      if(e.keyCode == 13){
         this.getPatrolTaskLog(1,10);
      }
    },
    /**
     * 查看大图
     * @param {*} flightLog 飞行记录信息
     * @param {Number} index 当前查看图片在所有图片中的下标
     * */
    lookBigImage(flightLog, index) {
      this.bigImageDialog = {
        visible: true,
        data: flightLog,
        initIndex: index,
      };
    },
    /**
     * 添加飞行截图
     * @param {*} result 查询到的oss结果信息
     * @param {*} ossClient 阿里云oss对象
     * */
    addFlightScreenshot(result, ossClient) {
      this.imageDialog.picture.push(
        ...result.objects.map((item) => {
          return {
            ...item,
            url: ossClient.signatureUrl(item.name),
            lastModified: dayjs(item.lastModified).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
          };
        })
      );
    },
    changeFlightScreenshot(index) {
      // 当到倒数第4张的时候触发加载后续的图片
      if (index >= this.imageDialog.picture.length - 5) {
        this.imageDialog.loadMore();
      }
    },
    async lookImage(item) {
      this.imageDialog = {
        visible: true,
        data: item,
        picture: [],
      };
      const ossClient = await this.createOss("oss-cn-beijing", "cosmos-robot");
      console.log(item.imageUrl);
      let result = await ossClient.list({
        prefix:item.imageUrl,
        "max-keys": 50,
      });
      console.log(result);
      this.addFlightScreenshot(result, ossClient);
      this.imageDialog.loadMore = async () => {
        if (result.isTruncated) {
          result = await ossClient.list({ marker: result.nextMarker });
          this.addFlightScreenshot(result, ossClient);
        }
      };
    },
    async createOss(region, bucket) {
      const { oss, stsToken } = this.stsTokenStorage;
      if (stsToken && stsToken.expireTime > new Date().getTime()) {
        return oss;
      }
      let res = await this.$fly.get("/account/staff/ossKey");
      let ossKeyData = res.data;
      const ossConfig = {
          region,
          accessKeyId: ossKeyData.accessKeyId,
          accessKeySecret: ossKeyData.accessKeySecret,
          bucket,
          stsToken: ossKeyData.token,
        },
        aliOss = new OSS(ossConfig);
      this.stsTokenStorage = {
        stsToken: ossKeyData,
        oss: aliOss,
      };
      return aliOss;
    },
    // 加载截图
    loadScreenshot(e) {
      console.log(
        e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight
      );
      if (
        e.target.scrollHeight - e.target.scrollTop <
        100 + e.target.clientHeight
      ) {
        this.imageDialog.loadMore();
      }
    },
    lookImgTwo(index) {
      this.imgIndex = index;
      this.lookImgVisibleTwo = true;
    },
    //查询单个巡检任务
    async getTask(item) {
      const taskL = await this.$fly.get(queryPatrolTaskByIdUrl + "/" + item);
      if (taskL.code != 0) {
        return;
      }
      return taskL.data;
    },
    changeTabBar(type = 0) {
      this.tabBarType = type;
      if (type == 0) {
      } else {
        this.queryRobotMapList();
      }
    },
    async lookPatrolPointDetails(item) {
      this.items = item;
      this.patrolPointDetailsVisible = true;
    },
    // 查询巡视任务记录
    async getPatrolTaskLog(pageNum = 1,pageSize = 10) {
      const res = await this.$fly.post(queryPatrolTaskLogByConditionUrl, {
        ...this.queryLogInfo,
        regionCode: this.regionCode,
        buildingCode: "",
        pageNum,
        pageSize,
      });
      if (res.code != 0) {
        return;
      }
      console.log(res.data);
      const flightLogs = [];
      for (let item of res.data.data) {
        const ossClient = await this.createOss(
          "oss-cn-beijing",
          "cosmos-robot"
        );
        let ossResult = await ossClient.list({
          prefix: item.imageUrl,
          "max-keys": 50,
        });
        if (ossResult.objects) {
          item.hasImage = ossResult.objects.length > 0;
        }
        flightLogs.push(item);
      }
      //this.patrolTaskLog = res.data.data;
      this.patrolTaskLog = flightLogs;
      if (pageNum === 1) {
        this.$vc.emit(this.$route.path, "pagination", "init", {
          total: res.data.total,
          pageSize,
          currentPage: pageNum,
        });
      }
    },
    lookVideo(item) {
      this.items = item;
      this.lookVideoVisible = true;
    },
  },
};
</script>

<style lang="stylus" scoped>
.video-drone-name {
  width: 960px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: absolute;
  top: 0;
  padding: 10px 20px;
  font-size: 20px;
  z-index: 99;
}

.zhanShiImg {
  width: 160px;
  height: 160px;
  margin-right: 40px;
  margin-bottom: 40px;
  display: inline-block;
  
}

div.zhanShiImg:nth-child(5n) {
  margin-right: 0;
}

.zhanShiImg img {
  width: 100%;
}

.img-wrapper {
  padding: 30px;
  max-height: 600px;
  overflow-y: auto;
}

.zhanShiImgTwo {
  width: 960px;
  height: 540px;
  overflow: hidden;
}

.zhanShiImgTwo img {
  width: 960px;
}

.btnImage {
  position: relative;
}

.btnImage .btnI {
  width: 80px;
  height: 80px;
  background: rgba(255, 255, 255, 0.39);
  border-radius: 50%;
  position: absolute;
  top: 230px;
}

.btnImage-left {
  left: 60px;
}

.btnImage-right {
  right: 20px;
}

.video-drone-name {
  width: 960px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: absolute;
  top: 0;
  padding: 10px 20px;
  font-size: 20px;
  z-index: 99;
}

.capture-img {
  padding: 30px;

  // max-height calc(160px * 3)
  .title {
    margin-bottom: 20px;
    font-size: 24px;
  }

  .content {
    max-height: calc(160px * 3);
    overflow-y: auto;

    .item-wrap {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 15px 40px;
    }

    .item {
      width: 160px;
      text-align: center;
      font-size: 14px;

      img {
        width: 100%;
        display: block;
        border-radius: 5px;
      }
    }
  }
}

.video-drone-name {
  width: 960px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: absolute;
  top: 0;
  padding: 12px 20px;
  font-size: 20px;
  z-index: 99;
}
</style>